// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-brand-js": () => import("./../../../src/pages/brand.js" /* webpackChunkName: "component---src-pages-brand-js" */),
  "component---src-pages-experiment-tracking-js": () => import("./../../../src/pages/experiment-tracking.js" /* webpackChunkName: "component---src-pages-experiment-tracking-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-model-registry-js": () => import("./../../../src/pages/model-registry.js" /* webpackChunkName: "component---src-pages-model-registry-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-request-demo-js": () => import("./../../../src/pages/request-demo.js" /* webpackChunkName: "component---src-pages-request-demo-js" */),
  "component---src-pages-security-and-privacy-js": () => import("./../../../src/pages/security-and-privacy.js" /* webpackChunkName: "component---src-pages-security-and-privacy-js" */),
  "component---src-pages-why-iterative-js": () => import("./../../../src/pages/why-iterative.js" /* webpackChunkName: "component---src-pages-why-iterative-js" */)
}

