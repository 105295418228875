module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Iterative","short_name":"Iterative","icon":"src/images/icon.png","start_url":"/","background_color":"#0f1624","theme_color":"#0f1624","display":"standalone","icons":[{"src":"/apple-touch-icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"/apple-touch-icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/apple-touch-icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/apple-touch-icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/apple-touch-icon.png","sizes":"180x180","type":"image/png"},{"src":"/apple-touch-icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/apple-touch-icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"/apple-touch-icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"/apple-touch-icon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"78541f5bcb19bcbcdc375b47b7bf2b5a"},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"denyUrls":[{},{}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
